<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="table-operator-title">{{ this.$route.name }}</div>
        <a-button type="primary" @click="add">添加</a-button>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="page" size="middle" @change="more">
          <span slot="action" slot-scope="text, record">
            <a-popconfirm title="确认删除？" @confirm="del(record)" v-if="record.pid != 0">
              <a-icon slot="icon" type="question-circle-o" style="color: red" />
              <a style="color: red">删除</a>
            </a-popconfirm>
            <a @click="edit(record)" class="ml-2">编辑</a>
            <a @click="getDtl(record)" class="ml-2">详情</a>
          </span>
        </a-table>
      </div>
    </div>
    <add ref="add" @ok="ok" />
    <a-modal :title="this.$route.name" :width="640" :visible="show" @ok="okedit" @cancel="show=false">
      <div class="flex-s">
        <div style="white-space: nowrap;">标题名称：</div>
        <a-input v-model="form.title" placeholder="请输入" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import add from "./modules/add"
import { calclist, addlist, delList,updateList } from "../../../api/index.js"

export default {
  components: {
    add
  },
  methods: {
    async more(e) {
      this.queryParam.page = e.current
      let res = await calclist(this.queryParam);
      if (res.status == 200) {
        this.item = res.data.records;
        this.page.total = res.data.total;
        this.page.current = res.data.current;
      }
    },
    change_time(e, str) {
      this.queryParam.time = str
    },
    edit(record) {
      this.form={
        id:record.id,
        title:record.title
      }
      this.show=true
      // this.$router.push({ path: '/target2', query: { id: record.id } })
    },
    getDtl(record){
      this.$router.push({ path: '/target2', query: { id: record.id } })
    },
    async okedit(){
      let res=await updateList(this.form)
      if(res.status==200){
        this.more({current:1})
        this.show=false
      }
    },
    async ok(e) {
      let res = await addlist({ title: e })
      if (res.status == 200) {
        this.more({ current: 1 })
      }
    },
    add() {
      this.$refs.add.init()
    },
    async del(e) {
      let res = await delList({ id: e.id })
      if (res.status == 200) {
        this.more({ current: 1 })
      }
    },
  },
  mounted() {
    this.more({ current: 1, size: 20 });
  },

  data() {
    return {
      id: 0,
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
        { title: 'ID', dataIndex: 'id', width: 140, align: "center" },
        { title: "标题", dataIndex: "title", align: "center" },
        { title: "操作", width: 160, scopedSlots: { customRender: "action" }, align: "center" },
      ],
      item: [],
      type: [],
      show:false,
      form:{
        id:'',
        title:''
      }
    };
  }
};
</script>

<style>
.log span {
  display: flex;
}
</style>