<template>
  <a-modal :title="this.$route.name" :width="640" :visible="visible" @ok="ok" @cancel="cancel">
    <div class="flex-s">
      <div style="white-space: nowrap;">标题名称：</div>
      <a-input v-model="value" placeholder="请输入" />
    </div>
  </a-modal>
</template>

<script>
import pick from "lodash.pick"

// 表单字段

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      value: ''
    }
  },
  created() {
  },
  methods: {
    init(e) {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    ok() {
      this.$emit('ok', this.value)
      this.visible = false
    }
  }
}
</script>

<style>
.p2 {
  padding-top: 20px;
}

.p2 .d3 {
  padding-top: 5px;
}

.p2 .d3 a {
  padding-bottom: 5px;
  display: flex
}</style>
